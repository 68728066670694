import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from '@mui/material';
import Footer from '../Footer/Footer';

const Broadcast = () => {
    const [documents, setDocuments] = useState([
        { 
            id: 1, 
            date: '14th of December 2022', 
            title: 'Song of affection for the people', 
            link: 'https://www.youtube.com/watch?v=AqAz5wQxW5A&feature=youtu.be',
            preview: 'https://www.youtube.com/embed/AqAz5wQxW5A' // Embed link for YouTube video
        },
        // Add more documents as needed
    ]);

    const [filter, setFilter] = useState('');

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filteredDocuments = documents.filter((doc) =>
        doc.title.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <div style={{ textAlign: 'center' }}>

            {/* Logo and text */}
            <div className="d-flex align-items-center" style={{ maxWidth: '100%', margin: '0 auto', marginTop: '10px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)' }}>
                <a href="/" style={{ textDecoration: 'none' }}>
                    <div className="d-flex justify-content-start bordered-div" style={{ maxWidth: '10%', margin: '10px 20px 10px 10px', borderRadius: '70px', borderColor: 'white' }}>
                        <img className="img-fluid" src="img/logo.png" alt="Central Revolutionary Committee" />
                    </div>
                </a>
                <div style={{ borderLeft: '2px solid red', height: '50px', margin: '0 90px', padding: '0 20px', marginLeft: '-70%' }}>
                    <p style={{ fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)' }}>
                        Broadcast 'The Revolutionary Voice on the world'
                    </p>
                </div>
            </div>

            <div style={{ marginBottom: '20px', marginTop: '50px' }}>
                <h2>Broadcast 'The Revolutionary Voice on the world'</h2>
                <TextField
                    label="Filter by Title"
                    variant="outlined"
                    value={filter}
                    onChange={handleFilterChange}
                    style={{ marginBottom: '10px', width: '50%', marginTop: '10px' }}
                />
            </div>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow style={{ fontWeight: 'bold', fontFamily: 'Jost' }}>
                            <TableCell align="left" style={{ fontWeight: 'bold', color: 'black' }}>Publication Date</TableCell>
                            <TableCell align="left" style={{ fontWeight: 'bold', color: 'black' }}>Title</TableCell>
                            <TableCell align="left" style={{ fontWeight: 'bold', color: 'black' }}>Preview</TableCell>
                            <TableCell align="left" style={{ fontWeight: 'bold', color: 'black' }}>Watch Video</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredDocuments.map((document) => (
                            <TableRow key={document.id}>
                                <TableCell>{document.date}</TableCell>
                                <TableCell>{document.title}</TableCell>
                                <TableCell>
                                    <iframe
                                        width="200"
                                        height="150"
                                        src={document.preview}
                                        title="Video preview"
                                        frameborder="0"
                                        allowfullscreen
                                    ></iframe>
                                </TableCell>
                                <TableCell>
                                    <Button variant="contained" color="primary" href={document.link} target="_blank">
                                        Watch Video
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Footer component */}
            {/* <Footer /> */}
        </div>
    );
};

export default Broadcast;
