import React from 'react';
import { Container, Typography, Grid } from '@mui/material';
import Footer from '../Footer/Footer';

const ContactUs = () => {
    return (
        <div style={{ width: '100%', height: 'auto', paddingTop: '20px' }}>
                <a href="/" style={{ textDecoration: 'none' }}>
                <div className="d-flex justify-content-start bordered-div" style={{ maxWidth: '10%', margin: '10px 0 0 10px', borderRadius: '70px', borderColor: 'white' }}>
                    <img className="img-fluid" src="img/logo.png" alt="Central Revolutionary Committee" />
                </div>
            </a>
            <div className="d-flex justify-content-center bordered-div"  style={{ maxWidth: '40%', margin: '0 auto',marginTop:'-10%' }}>
                <img className="img-fluid" src="img/contact.png" alt="Central Revolutionary Committee" />
            </div>
            <br></br>
            <Container maxWidth="md">
                <Typography variant="h4" align="center" style={{ marginBottom: '20px' }}>
                    Contact Us
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            For inquiries or more information, please contact us:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" component="div">
                            <span style={{ fontWeight: 'bold' }}>Email:</span> ircp.crc@yahoo.com
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" component="div">
                            <span style={{ fontWeight: 'bold' }}>Phone:</span> +123 456 789
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" component="div">
                            <span style={{ fontWeight: 'bold' }}>Address:</span> 123 Revolution Street, Red Square
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </div>
    );
};

export default ContactUs;
