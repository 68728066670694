import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Footer from '../Footer/Footer';

const Resolutions = () => {
    const [documents, setDocuments] = useState([
        {
            id: 1,
            date: '14th of September 2022',
            title: 'IRCP Resolution 1(1) IX 2022',
            link: 'https://reader.crcircp.com/2024/07/08/ircp-resolution-11-ix-2022/',
            status: 'Valid',
            announcementDate: '14th of September',
            publicationJournal: 'IRCP Resolution 1(1) IX 2022',
            entryNumber: '1(1)'
        },
        {
            id: 2,
            date: '7th of July 2024',
            title: 'IRCP Resolution 15(2) VI 2024',
            link: 'https://reader.crcircp.com/2024/07/08/ircp-resolution-152-vi-2024/',
            status: 'Valid',
            announcementDate: '7th of July',
            publicationJournal: 'IRCP Resolution 15(2) VI 2024',
            entryNumber: '15(2)'
        },
        {
            id: 3,
            date: '7th of July 2024',
            title: 'IRCP Resolution 14(1) I 2024',
            link: 'https://reader.crcircp.com/2024/07/08/ircp-resolution-141-i-2024-2/',
            status: 'Valid',
            announcementDate: '7th of July',
            publicationJournal: 'IRCP Resolution 14(1) I 2024',
            entryNumber: '14(1)'
        },
        {
            id: 4,
            date: '7th of July 2024',
            title: 'IRCP Resolution 13(8) XII 2023',
            link: 'https://reader.crcircp.com/2024/07/08/ircp-resolution-138-xii-2023-2/',
            status: 'Valid',
            announcementDate: '7th of July',
            publicationJournal: 'IRCP Resolution 13(8) XII 2023',
            entryNumber: '13(8)'
        },
        
        {
            id: 5,
            date: '6th of December',
            title: 'Political Resolution No. 12(7) XII 2023',
            link: 'https://reader.crcircp.com/2023/12/18/political-resolution-no-127-xii-2023',
            status: 'Valid',
            announcementDate: '6th of December',
            publicationJournal: 'CRC-IRCP Resolution Journal, 2023',
            entryNumber: '12(7)'
        },
        {
            id: 6,
            date: '13th of October 2023',
            title: 'Support Resolution No. 11(6) X 2023',
            link: 'https://reader.crcircp.com/2023/10/12/support-resolution-no-116-x-2023',
            status: 'Valid',
            announcementDate: '13th of October',
            publicationJournal: 'CRC-IRCP Resolution Journal, 2023',
            entryNumber: '11(6)'
        },

        {
            id: 7,
            date: '8th of October 2023',
            title: 'Resolution No. 10(5) X 2023',
            link: 'https://reader.crcircp.com/2023/10/12/resolution-no-105-x-2023',
            status: 'Valid',
            announcementDate: '8th of October',
            publicationJournal: 'CRC-IRCP Resolution Journal, 2023',
            entryNumber: '10(3)'
        },
        {
            id: 8,
            date: '2nd of September 2023',
            title: 'Resolution No. 9(4) VIII 2023',
            link: 'https://reader.crcircp.com/2023/09/05/resolution-no-94-viii-2023',
            status: 'Valid',
            announcementDate: '2nd of September',
            publicationJournal: 'CRC-IRCP Resolution Journal, 2023',
            entryNumber: '9(4)'
        },
        {
            id: 9,
            date: '19th of June 2023',
            title: 'Political Resolution No. 8(3) VI 2023',
            link: 'https://reader.crcircp.com/2023/08/08/ircp-resolution-83-vi-2023',
            status: 'Valid',
            announcementDate: '19th of June',
            publicationJournal: 'CRC-IRCP Resolution Journal, 2023',
            entryNumber: '8(3)'
        },
        {
            id: 10,
            date: '6th of March 2023',
            title: 'Support Resolution No. 7(2) III 2023',
            link: 'https://reader.crcircp.com/2023/03/07/support-resolution-no-72-iii-2023',
            status: 'Valid',
            announcementDate: '6th of March',
            publicationJournal: 'CRC-IRCP Resolution Journal, 2023',
            entryNumber: '7(2)'
        },
        {
            id: 11,
            date: '23rd of February 2023',
            title: 'Political Resolution No. 6(1) II 2023',
            link: 'https://reader.crcircp.com/2023/03/07/political-resolution-no-61-ii-2023',
            status: 'Valid',
            announcementDate: '23rd of February',
            publicationJournal: 'CRC-IRCP Resolution Journal, 2023',
            entryNumber: '6(1)'
        },
        {
            id: 12,
            date: '15th of December 2022',
            title: 'The Secretariat of the Central Revolutionary Committee of Internationalist Revolutionary Communist Party in the process of formation issued the Resolution Regarding to winter - inhuman barbarism, of UK energy companies, expressed in merciless, selfish and anti - human greed, for financial profits, at the cost of the lives and health, of the British working class.',
            link: 'https://reader.crcircp.com/2022/12/17/the-secretariat-of-the-central-revolutionary-committeeof-internationalist-revolutionary-communist-partyin-the-process-of-formationissued-the-resolutionregarding-to-winter-inhuman-barbarismof-uk/',
            status: 'Valid',
            announcementDate: '15th of December',
            publicationJournal: 'CRC-IRCP Resolution Journal, 2022',
            entryNumber: '5'
        },
        {
            id: 13,
            date: '1st of November 2022',
            title: 'The Central Revolutionary Committee of Internationalist Revolutionary Communist Party in the process of formation issued the Resolution regarding to social - economical and political situation, with anti - social actions of the British government and the ongoing process of impoverishment by rising living costs with the increasing profits of capitalist bourgeois',
            link: 'https://reader.crcircp.com/2022/12/17/the-central-revolutionary-committee-of-internationalist-revolutionary-communist-party-in-the-process-of-formation-issued-the-resolution-regarding-to-social-economical-and-political-situation-wi/',
            status: 'Valid',
            announcementDate: '1st of November',
            publicationJournal: 'CRC-IRCP Resolution Journal, 2022',
            entryNumber: '4'
        },
        {
            id: 14,
            date: '10th of October 2022',
            title: 'The Central Revolutionary Committee of Internationalist Revolutionary Communist Party in the process of forming issued Resolution regarding to 77th - Anniversary of the founding of the Workers Party of Korea',
            link: 'https://reader.crcircp.com/2022/12/17/the-central-revolutionary-committee-of-internationalist-revolutionary-communist-party-in-the-process-of-forming-issued-resolution-regarding-to-77th-anniversary-of-the-founding-of-the-workers-p/',
            status: 'Valid',
            announcementDate: '10th of October',
            publicationJournal: 'CRC-IRCP Resolution Journal, 2022',
            entryNumber: '3'
        },
        {
            id: 15,
            date: '21st of September 2022',
            title: 'The Central Revolutionary Committee of Internationalist Revolutionary Communist Party issued Resolution regarding to 18th Anniversary of Communist Party of India (Maoist)\'s with Red Fighters of the People\'s Liberation Guerilla Army ( PLGA )',
            link: 'https://reader.crcircp.com/2022/12/17/the-central-revolutionary-committeeof-internationalist-revolutionary-communist-partyissued-resolutionregarding-to-18th-anniversaryof-communist-party-of-india-maoistswith-red-fighters-of-the-pe/',
            status: 'Valid',
            announcementDate: '21st of September',
            publicationJournal: 'CRC-IRCP Resolution Journal, 2022',
            entryNumber: '2'
        },
        {
            id: 16,
            date: '14th of September 2022',
            title: 'Regarding the death of Queen Elizabeth II, the Internationalist Revolutionary Communist Party, in the process of being formed, expresses its position.',
            link: 'https://reader.crcircp.com/2022/12/17/regarding-the-death-of-queen-elizabeth-ii-the-internationalist-revolutionary-communist-partyin-the-process-of-being-formedexpresses-its-position/',
            status: 'Valid',
            announcementDate: '14th of September',
            publicationJournal: 'CRC-IRCP Resolution Journal, 2022',
            entryNumber: '1'
        },
       
    ]);

    const [filter, setFilter] = useState('');
    const [selectedResolution, setSelectedResolution] = useState(null);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handleResolutionClick = (document) => {
        setSelectedResolution(document);
    };

    const handleCloseDialog = () => {
        setSelectedResolution(null);
    };

    const filteredDocuments = documents.filter((doc) =>
        doc.title.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <div style={{ textAlign: 'center' }}>

            {/* Logo and text */}
            <div className="d-flex align-items-center" style={{ maxWidth: '100%', margin: '0 auto', marginTop: '10px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)' }}>
                <a href="/" style={{ textDecoration: 'none' }}>
                    <div className="d-flex justify-content-start bordered-div" style={{ maxWidth: '10%', margin: '10px 20px 10px 10px', borderRadius: '70px', borderColor: 'white' }}>
                        <img className="img-fluid" src="img/logo.png" alt="Central Revolutionary Committee" />
                    </div>
                </a>
                <div style={{ borderLeft: '2px solid red', height: '50px', margin: '0 90px', padding: '0 20px', marginLeft: '-70%' }}>
                    <p style={{ fontWeight: 'bold', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.4)' }}>
                        Resolution Journal of the Central Revolutionary Committee of the Internationalist Revolutionary Communist Party
                    </p>
                </div>
            </div>

            <div style={{ marginBottom: '20px', marginTop: '50px' }}>
                <h2>Journal of Resolutions, Guidelines and Circulars of the IRCP</h2>
                <TextField
                    label="Filter by Title"
                    variant="outlined"
                    value={filter}
                    onChange={handleFilterChange}
                    style={{ marginBottom: '10px', width: '50%', marginTop: '10px' }}
                />
            </div>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                    <TableRow style={{ fontFamily: 'Jost' }}>
                        <TableCell align="left" style={{ fontWeight: 'bold', color: 'black' }}>Publication Date</TableCell>
                        <TableCell align="left" style={{ fontWeight: 'bold', color: 'black' }}>Title</TableCell>
                        
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredDocuments.map((document) => (
                            <TableRow key={document.id} onClick={() => handleResolutionClick(document)} style={{ cursor: 'pointer' }}>
                                <TableCell>{document.date}</TableCell>
                                <TableCell>{document.title}</TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog for Resolution Details */}
            <Dialog open={selectedResolution !== null} onClose={handleCloseDialog}>
                <DialogTitle>Resolution view</DialogTitle>
                <DialogContent>
                    {selectedResolution && (
                        <>
                           
                            <br />
                            {selectedResolution.status === 'Valid' ? (
                                <p style={{ color: 'green', fontWeight: 'bold' }}>Status: {selectedResolution.status}</p>
                            ) : (
                                <p style={{ color: 'red', fontWeight: 'bold' }}>Status: {selectedResolution.status}</p>
                            )}
                            <p>Announcement date: {selectedResolution.announcementDate}</p>
                            <p>Publication journal: {selectedResolution.publicationJournal}, entry no.: {selectedResolution.entryNumber}</p>
                            <Button variant="contained" color="primary" style={{ marginBottom: '10px' }} href={selectedResolution.link} target="_blank">
                                Read Resolution
                            </Button>
                        </>

                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <Footer /> */}
        </div>
    );
};

export default Resolutions;
